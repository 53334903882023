@import '../../../../Styles/variables';

.Logo {
  perspective: 60rem;
  backface-visibility: hidden;
  position: relative;

  &_landing {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: neonShadow 20000ms ease-in-out infinite;
    
    // 1550px x 750px
    @media only screen and (max-width: 96.88em) and (max-height: 46.88em) {
      top: 42%;
    }
    
    // 1100px x 1400px
    @media only screen and (max-width: 68.75em) and (max-height: 87.5em) {
      top: 47%;
    }
    
    // 1100px x 800px
    @media only screen and (max-width: 68.75em) and (max-height: 50em) {
      top: 41%;
    }
    
    // 800px x 1100px
    @media only screen and (max-width: 50em) and (max-height: 68.75em) {
      top: 45%;
    }
    
    // 450px x 900px
    @media only screen and (max-width: 28.13em) and (max-height: 56.25em) {
      top: 43.5%;
    }
    
    // 450px x 800px
    @media only screen and (max-width: 28.13em) and (max-height: 50em) {
      top: 41.5%;
    }
    
    // 400px x 700px
    @media only screen and (max-width: 25em) and (max-height: 43.75em) {
      top: 42%;
    }
    
    // 350px x 600px
    @media only screen and (max-width: 21.88em) and (max-height: 37.5em) {
      top: 45%;
    }
  }

  &__Eyes {
    display: block;
    position: absolute;
    z-index: -10;
    animation: neonEyes 20000ms ease-in-out infinite;

    &_landing {
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 15rem;
      height: 5rem;
      
      // 450px x 900px
      @media only screen and (max-width: 28.13em) and (max-height: 56.25em) {
        top: 44%;
        width: 10rem;
        height: 3rem;
      }
    }
  }

  &__Img {
    display: block;
    width: 27.5rem;
    height: 27.5rem;

    // 1100px x 800px
    @media only screen and (max-width: 68.75em) and (max-height: 50em) {
      width: 22rem;
      height: 22rem;
    }

    // 800px x 1100px
    @media only screen and (max-width: 50em) and (max-height: 68.75em) {
      width: 26rem;
      height: 26rem;
    }
    
    // 450px x 900px
    @media only screen and (max-width: 28.13em) and (max-height: 56.25em) {
      width: 23rem;
      height: 23rem;
    }

    // 400px x 700px
    @media only screen and (max-width: 25em) and (max-height: 43.75em) {
      width: 21rem;
      height: 21rem;
    }
    
    // 350px x 600px
    @media only screen and (max-width: 21.88em) and (max-height: 37.5em) {
      width: 19.5rem;
      height: 19.5rem;
    }
  }
}

@keyframes neonShadow {
  0% {
    filter: drop-shadow(0 0 0 $pink1);
  }
  
  12.5% {
    filter: drop-shadow(0 0 45px $pink1);
  }

  25% {
    filter: drop-shadow(0 0 0 $green1);
  }
  
  37.5% {
    filter: drop-shadow(0 0 45px $green1);
  }
  
  50% {
    filter: drop-shadow(0 0 0 $yellow1);
  }
  
  62.5% {
    filter: drop-shadow(0 0 45px $yellow1);
  }
  
  75% {
    filter: drop-shadow(0 0 0 $blue1);
  }
  
  87.5% {
    filter: drop-shadow(0 0 45px $blue1);
  }
  
  100% {
    filter: drop-shadow(0 0 0 $pink1);
  }
}

@keyframes neonEyes {
  0%,
  12.5% {
    box-shadow: inset 0 0 1000px $pink1;
  }
  
  25%,
  37.5% {
    box-shadow: inset 0 0 1000px $green1;
  }
  
  50%,
  62.5% {
    box-shadow: inset 0 0 1000px $yellow1;
  }
  
  75%,
  87.5% {
    box-shadow: inset 0 0 1000px $blue1;
  }
  
  100% {
    box-shadow: inset 0 0 1000px $pink1;
  }
}