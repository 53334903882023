@import '../../../../Styles/variables';

.Role {
  display: block;
  backface-visibility: hidden;

  &_portfolio {
    width: max-content;
  }
  
  &__Letters {
    display: inline-block;
    backface-visibility: hidden;
    
    &_portfolio {
      text-transform: uppercase;
      font-size: $small-font-size;
      letter-spacing: $small-letter-spacing;
      font-weight: bold;
      text-shadow: 0 0 1px #333;

      // 1450px
      @media only screen and (max-width: 90.63em) {
        font-size: $smaller-font-size;
      }

      // 1150px
      @media only screen and (max-width: 71.88em) {
        letter-spacing: $smallest-letter-spacing;
      }

      // 900px
      @media only screen and (max-width: 56.25em) {
        letter-spacing: 0;
      }
      
      // 600px x <400px
      @media only screen and (max-width: 37.5em) and (max-height: 25em) {
        font-size: $smaller-font-size;
      }
    }
  }
}