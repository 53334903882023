@import '../../../Styles/variables';

.ThreeJsBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  overflow: hidden;  
  z-index: -10;
}