.Landing {
  position: relative;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  transform-style: preserve-3d;   
  transform: translateZ(0);
  
  &__Details {
    height: 100%;
    width: 100%;
  }
}