@import '../../../../Styles/variables';

.PageIndicatorBar {
  display: block;
  text-align: center;
  position: absolute;
  bottom: $handle-height / 2;
  left: 50%;
  transform: translate(-50%, 0);
  backface-visibility: hidden;
  
  &_left,
  &_right {
    display: block;
    position: absolute;
    height: 4px;
    background-color: #fff;
    border-radius: 100px;    
    backface-visibility: hidden;
  }
  
  &_left {
    left: 0;
    right: 50%;
  }
  
  &_right {
    right: 0;
    left: 50%;
  } 
}