@import '../../../../Styles/variables';

.ProgressLogo {
  position: absolute;
  top: 50%; 
  left: 50%;
  transform: translate(-50%, -50%);
  backface-visibility: hidden;
  transition: all 500ms ease-out;
  
  &__Image {
    display: block;
    width: 100%;
    height: auto;
    animation: neonShadow 20000ms ease-in-out infinite;
    backface-visibility: hidden;
  }

  &__ContactForm {
    width: 14rem;
  }
  
  &__ProgressBar {
    width: 13rem;
    opacity: 0;
  }
}

@keyframes neonShadow {
  0% {
    filter: drop-shadow(0 0 0 $pink1);
  }
  
  12.5% {
    filter: drop-shadow(0 0 25px $pink1);
  }

  25% {
    filter: drop-shadow(0 0 0 $green1);
  }
  
  37.5% {
    filter: drop-shadow(0 0 25px $green1);
  }
  
  50% {
    filter: drop-shadow(0 0 0 $yellow1);
  }
  
  62.5% {
    filter: drop-shadow(0 0 25px $yellow1);
  }
  
  75% {
    filter: drop-shadow(0 0 0 $blue1);
  }
  
  87.5% {
    filter: drop-shadow(0 0 25px $blue1);
  }
  
  100% {
    filter: drop-shadow(0 0 0 $pink1);
  }
}