@import '../../../../Styles/variables';

.Backdrop {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#000000, .95);
  z-index: 1000000;
  transition: all 700ms ease-out 250ms;
  backface-visibility: hidden;
  transform: translateZ(0);
  
  &_enter {
    opacity: 0;
  }

  &_enter_active {
    opacity: 1;
  }
  
  &_enter_done {
    opacity: 1;
  }
  
  &_exit {
    opacity: 1;
  }

  &_exit_active {
    opacity: 0;
  }

  &_exit_done {
    opacity: 0;
  }
}